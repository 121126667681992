import "prismjs";
import "./configs/i18n";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { Suspense, lazy } from "react";
import { Toaster } from "react-hot-toast";
import "./@core/components/ripple-button";
import "prismjs/components/prism-jsx.min";
import "prismjs/themes/prism-tomorrow.css";
import { createRoot } from "react-dom/client";
import themeConfig from "./configs/themeConfig";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import "./@core/assets/fonts/feather/iconfont.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import { ThemeContext } from "./utility/context/ThemeColors";
import Spinner from "./@core/components/spinner/Fallback-spinner";
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss";
import { AdminCustomerContextProvider } from "./utility/context/useAdminContext";
import { PrivateLabelContextProvider } from "./utility/context/privateLabelContetx";
import { FilterTeamProvider } from "./utility/context/useFilterTeam";

const LazyApp = lazy(() => import("./App"));

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <PrivateLabelContextProvider>
    <BrowserRouter>
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
          <AdminCustomerContextProvider>
            <FilterTeamProvider>
              <ThemeContext>
                <LazyApp />
                <Toaster
                  position={themeConfig.layout.toastPosition}
                  toastOptions={{
                    className: "react-hot-toast",
                    duration: 7000,
                  }}
                />
              </ThemeContext>
            </FilterTeamProvider>
          </AdminCustomerContextProvider>
        </Suspense>
      </Provider>
    </BrowserRouter>
  </PrivateLabelContextProvider>
);

serviceWorker.unregister();
