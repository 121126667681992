import React, {
  useState,
  createContext,
  useContext,
  useMemo,
  ReactNode,
} from "react";

type FilterTeamContextType = {
  selectedFilterTeam: "DTI" | "Title" | null;
  setSelectedFilterTeam: React.Dispatch<
    React.SetStateAction<"DTI" | "Title" | null>
  >;
};

const FilterTeamContext = createContext<FilterTeamContextType | undefined>(
  undefined
);

export const useFilterTeam = () => {
  const context = useContext(FilterTeamContext);
  if (!context) {
    throw new Error(
      "useFilterTeam debe ser usado dentro de FilterTeamProvider"
    );
  }
  return context;
};

type FilterTeamProviderProps = {
  children: ReactNode;
};

export const FilterTeamProvider = ({ children }: FilterTeamProviderProps) => {
  const [selectedFilterTeam, setSelectedFilterTeam] = useState<
    "DTI" | "Title" | null
  >(null);

  const value = useMemo(
    () => ({ selectedFilterTeam, setSelectedFilterTeam }),
    [selectedFilterTeam, setSelectedFilterTeam]
  );

  return (
    <FilterTeamContext.Provider value={value}>
      {children}
    </FilterTeamContext.Provider>
  );
};
